<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="대기 배출구 목록"
          tableId="table"
          rowKey="envAirMstOutletName"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :selection="popupParam.type"
          :columnSetting="false"
          @rowDblclick="rowDblclick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
              <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'air-master-01-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'single',
        plantCd: '',
      }),
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: '',
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        width: '80%',
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width: 150px',
            sortable: true,
          },
          {
            name: 'envAirMstOutletName',
            field: 'envAirMstOutletName',
            label: '배출구 일련번호',
            align: 'center',
            sortable: true,
          },
          {
            name: 'location',
            field: 'location',
            label: 'LBLLOCATION',
            align: 'center',
            sortable: true,
          },
          {
            name: 'handlingCapacity',
            field: 'handlingCapacity',
            label: '처리용량(㎥/분)',
            align: 'right',
            type: 'cost',
            style: 'width: 200px',
            sortable: true,
          },
          {
            name: 'permitCapacity',
            field: 'permitCapacity',
            label: '설계(허가증)용량(㎥/분)',
            align: 'right',
            type: 'cost',
            style: 'width: 200px',
            sortable: true,
          },
          // {
          //   name: 'buyDt',
          //   field: 'buyDt',
          //   label: '구입일자',
          //   align: 'center',
          //   style: 'width: 150px',
          //   sortable: true,
          // },
          // {
          //   name: 'maker',
          //   field: 'maker',
          //   label: '메이커',
          //   align: 'center',
          //   style: 'width: 200px',
          //   sortable: false,
          // },
        ],
        data: [],
      },
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      }
      this.listUrl = selectConfig.env.air.mst.outlet.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '배출구를 선택하세요.', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
